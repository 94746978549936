/**
 * Created by 芋道源码
 *
 * 数据字典工具类
 */
import store from '@/store'

export const DICT_TYPE = {
  USER_TYPE: 'user_type',
  COMMON_STATUS: 'common_status',

  // ========== SYSTEM 模块 ==========
  SYSTEM_USER_SEX: 'system_user_sex',
  SYSTEM_MENU_TYPE: 'system_menu_type',
  SYSTEM_ROLE_TYPE: 'system_role_type',
  SYSTEM_DATA_SCOPE: 'system_data_scope',
  SYSTEM_NOTICE_TYPE: 'system_notice_type',
  SYSTEM_OPERATE_TYPE: 'system_operate_type',
  SYSTEM_LOGIN_TYPE: 'system_login_type',
  SYSTEM_LOGIN_RESULT: 'system_login_result',
  SYSTEM_SMS_CHANNEL_CODE: 'system_sms_channel_code',
  SYSTEM_SMS_TEMPLATE_TYPE: 'system_sms_template_type',
  SYSTEM_SMS_SEND_STATUS: 'system_sms_send_status',
  SYSTEM_SMS_RECEIVE_STATUS: 'system_sms_receive_status',
  SYSTEM_ERROR_CODE_TYPE: 'system_error_code_type',
  SYSTEM_OAUTH2_GRANT_TYPE: 'system_oauth2_grant_type',
  SYSTEM_MAIL_SEND_STATUS: 'system_mail_send_status',

  // ========== INFRA 模块 ==========
  INFRA_BOOLEAN_STRING: 'infra_boolean_string',
  INFRA_BOOLEAN_VALUE: 'infra_boolean_value',
  INFRA_REDIS_TIMEOUT_TYPE: 'infra_redis_timeout_type',
  INFRA_JOB_STATUS: 'infra_job_status',
  INFRA_JOB_LOG_STATUS: 'infra_job_log_status',
  INFRA_API_ERROR_LOG_PROCESS_STATUS: 'infra_api_error_log_process_status',
  INFRA_CONFIG_TYPE: 'infra_config_type',
  INFRA_CODEGEN_TEMPLATE_TYPE: 'infra_codegen_template_type',
  INFRA_CODEGEN_SCENE: 'infra_codegen_scene',
  INFRA_FILE_STORAGE: 'infra_file_storage',

  // ========== BPM 模块 ==========
  BPM_MODEL_CATEGORY: 'bpm_model_category',
  BPM_MODEL_FORM_TYPE: 'bpm_model_form_type',
  BPM_TASK_ASSIGN_RULE_TYPE: 'bpm_task_assign_rule_type',
  BPM_PROCESS_INSTANCE_STATUS: 'bpm_process_instance_status',
  BPM_PROCESS_INSTANCE_RESULT: 'bpm_process_instance_result',
  BPM_TASK_ASSIGN_SCRIPT: 'bpm_task_assign_script',
  BPM_OA_LEAVE_TYPE: 'bpm_oa_leave_type',

  // ========== PAY 模块 ==========
  PAY_CHANNEL_WECHAT_VERSION: 'pay_channel_wechat_version', // 微信渠道版本
  PAY_CHANNEL_ALIPAY_SIGN_TYPE: 'pay_channel_alipay_sign_type', // 支付渠道支付宝算法类型
  PAY_CHANNEL_ALIPAY_MODE: 'pay_channel_alipay_mode', // 支付宝公钥类型
  PAY_CHANNEL_ALIPAY_SERVER_TYPE: 'pay_channel_alipay_server_type', // 支付宝网关地址
  PAY_CHANNEL_CODE_TYPE: 'pay_channel_code_type', // 支付渠道编码类型
  PAY_ORDER_NOTIFY_STATUS: 'pay_order_notify_status', // 商户支付订单回调状态
  PAY_ORDER_STATUS: 'pay_order_status', // 商户支付订单状态
  PAY_ORDER_REFUND_STATUS: 'pay_order_refund_status', // 商户支付订单退款状态
  PAY_REFUND_ORDER_STATUS: 'pay_refund_order_status', // 退款订单状态
  PAY_REFUND_ORDER_TYPE: 'pay_refund_order_type', // 退款订单类别

  // ========== MALL - PRODUCT 模块 ==========
  PRODUCT_SPU_STATUS: 'product_spu_status', // 商品 SPU 状态

  // ========== MALL - PROMOTION 模块 ==========
  PROMOTION_DISCOUNT_TYPE: 'promotion_discount_type', // 优惠类型
  PROMOTION_PRODUCT_SCOPE: 'promotion_product_scope', // 营销的商品范围
  PROMOTION_COUPON_TEMPLATE_VALIDITY_TYPE: 'promotion_coupon_template_validity_type', // 优惠劵模板的有限期类型
  PROMOTION_COUPON_STATUS: 'promotion_coupon_status', // 优惠劵的状态
  PROMOTION_COUPON_TAKE_TYPE: 'promotion_coupon_take_type', // 优惠劵的领取方式
  PROMOTION_ACTIVITY_STATUS: 'promotion_activity_status', // 优惠活动的状态
  PROMOTION_CONDITION_TYPE: 'promotion_condition_type', // 营销的条件类型枚举

  // ========== 枚举字典  ==========
  CURRENCYENUM: 'CurrencyEnum',
  INBOUNDPACKAGESTATUS: 'InboundPackageStatus',
  ORDERSTATUS: 'OrderStatus',
  ORDERITEMSTATUS: 'OrderItemStatus',
  OUTBOUNDPACKAGESTATUS: 'OutboundPackageStatus',
  SHOPTYPE: 'ShopType',
  PURCHASEORDERSTATUS: 'PurchaseOrderStatus',
  TICKET_TYPE_ENUM: 'TicketTypeEnum',
  TICKET_SUB_TYPE_ENUM: 'TicketSubTypeEnum',
  TICKET_STATUS_ENUM: 'TicketStatusEnum',
  ACTIVITY_SHARE_STATUS_ENUM: 'ActivityShareStatusEnum',
  LANGUAGE_ENUM: 'LanguageEnum',
  INBOUND_REFER_QUANTITY_TYPE: 'InboundReferQuantityType',
  CONTROLLER_CLASS_ENUM: 'ControllerClassEnum',
  BUYER_BLACKLIST_FROM_TYPE: 'BuyerBlacklistFromType',
  ADDEDSERVICEPRICETYPE: 'AddedServicePriceType',
  ADDEDSERVICEPAGEPOSITION: 'AddedServicePagePosition',
  ADDEDSERVICEIMPLWAY: 'AddedServiceImplWay',
  BUSINESSTYPE: 'BusinessType',
  VALUEADDEDSERVICETYPE: 'ValueAddedServiceType',
  PAIDSTATUS: 'PaidStatus',
  REC_PRODUCT_RESOURCE: 'RecProductSourceEnum',
  WAREHOUSE_OUTBOUND_PACKAGE_STATUS: 'WarehouseOutboundPackageStatus',
  AFTERSALESTYPE: 'AfterSalesType',
  REFUNDSTATUS: 'RefundStatus',
  ITEMGOODSSTATUS: 'ItemGoodsStatus',
  REFUNDCHANNELTYPE: 'RefundChannelType',
  INBOUND_ITEM_STATUS: 'InboundItemStatus',
  LOGISTICS_SUPPLIER_DELIVERY_METHOD: 'LogisticsSupplierDeliveryMethod',
  INBOUND_PACKAGE_TYPE: 'InboundPackageType',
  INBOUND_ITEM_INBOUND_INSPECT_RESULT: 'InboundItemInboundInspectResult',
  STORAGELOCATIONTYPE: 'StorageLocationType',
  INBOUND_ITEM_PHASE: 'InboundItemPhase',
  EMS_BIZ_PRODUCT: 'EmsBizProduct',
  EMS_BIZ_CUSTOMER: 'EmsBizCustomer',
  INSURANCETYPE: 'InsuranceType',
  INSURANCEPOLICYSTATUS: 'InsurancePolicyStatus',
  TRACKING_QUERY_WAY: 'TrackingQueryWay',
  STOCKTAKESTATUS: 'StocktakeStatus',
  SELLER_BLACK_REASONS: 'seller_black_reasons',
  PURCHASE_ORDER_PAY_ACCOUNT: 'PurchaseOrderPayAccount',
  PRINT_OUTBOUND_ORDER_METHOD: 'PrintOutboundOrderMethod',
  COOPERATIONTYPE: 'CooperationType',
  RISK_CONTROL_RULE_HANDLER: 'RuleHandlerEnum',
  PAYMENT_STATUS: 'PaymentStatusEnum',
  MANUAL_REVIEW_STATUS: 'ManualReviewStatusEnum',
  PURCHASEORDERPAYACCOUNT: 'PurchaseOrderPayAccount',
  PURCHASEFUNDLOGTYPE: 'PurchaseFundLogType',
  INBOUND_ITEM_EVENT: 'InboundItemEvent',
  SHOW_API_TRACKING_STATUS: 'ShowApiTrackingStatus',
  ANSWER_EVALUATION_ENUM: 'AnswerEvaluationEnum',
  OUTBOUND_PACKAGE_TYPE: 'OutboundPackageType',
  TAOBAO_IOP_ACCOUNT_TYPE: 'TaobaoIopAccountType',
  ORDER_ITEM_TYPE: 'OrderItemType',
  CREATE_ORDER_METHOD_TYPE:'CreateOrderMethodTypeEnum',
  PURCHASE_ACCOUNT_BELONG_TYPE:'PurchaseAccountBelongType',
  COUPON_ISSUANCE_REASON:'coupon_issuance_reason',
  COUPON_DISCOUNT_TYPE:'coupon_discount_type',
  USER_BALANCE_TXN_TYPE: 'user-balance-txn-type',
  USER_BALANCE_BIZ_TYPE: 'user-balance-biz-type',
  OUTBOUND_PICK_PREPARE_STATUS: 'OutboundPickPrepareStatus',
  RECONCILETYPEENUM: 'ReconcileTypeEnum',
  COMPLETE_STATUS: 'CompleteStatus',
  TAG_INTERCEPT_TYPE: 'TagInterceptType',
  ORDER_ITEM_SKU_INFO_APPLY_TO: 'OrderItemSkuInfoApplyTo',
  ORDER_ITEM_SKU_TYPE: 'OrderItemSkuType',
  FUND_LOG_AUDIT_STATUS: 'FundLogAuditStatus',
  ORDER_GAIN_TYPE: 'OrderGainType',
  ORDER_GAIN_POOL: 'OrderGainPool',
  CATEGORYTYPEENUM: 'CategoryTypeEnum',
  TIMEPERIOD: 'TimePeriod',
  INNERTICKETSOURCEENUM: 'InnerTicketSourceEnum',
  INNERTICKETSTATUSENUM: 'InnerTicketStatusEnum',
  STATIONMESSAGEEVENTTYPE:'StationMessageEventType',
  STATION_MESSAGE_TYPE:'StationMessageType',
  DECLARE_CUSTOMS_CODE_STATUS:'DeclareCustomsCodeStatus',
  PLATFORM_REFUND_STATUS:'PlatformRefundStatus',
  VIPGROWTHTYPEENUM:'VipGrowthTypeEnum',
}

/**
 * 获取 dictType 对应的数据字典数组
 *
 * @param dictType 数据类型
 * @returns {*|Array} 数据字典数组
 */
export function getDictDatas(dictType) {
  // this.getDictDatas(DICT_TYPE.INBOUNDPACKAGESTATUS) 掉到这里
  // console.log("getDictDatas",dictType);
  return store.getters.dict_datas[dictType] || []
}

export function getDictDataMap_labelAsKey(dictType) {
  const dictArray = store.getters.dict_datas[dictType] || [];

  const map = {}

  for(const dict of dictArray ){
    map[dict.label] = dict.value
  }
  return map
}

export function getDictDataMap_valueAsKey(dictType) {
  const dictArray = store.getters.dict_datas[dictType] || [];

  const map = {}

  for(const dict of dictArray ){
    map[dict.value] = dict.label
  }

  return map
}

/**
 * 获取 dictType 对应的数据字典数组
 *
 * @param dictType 数据类型
 * @param values 数组、单个元素
 * @returns {*|Array} 数据字典数组
 */
export function getDictDatas2(dictType, values) {
  if (values === undefined) {
    return [];
  }
  // 如果是单个元素，则转换成数组
  if (!Array.isArray(values)) {
    values = [this.value];
  }
  // 获得字典数据
  const results = [];
  for (const value of values) {
    const dict = getDictData(dictType, value);
    if (dict) {
      results.push(dict);
    }
  }
  return results;
}

export function getDictData(dictType, value) {
  // 获取 dictType 对应的数据字典数组
  const dictDatas = getDictDatas(dictType)
  if (!dictDatas || dictDatas.length === 0) {
    return ''
  }
  // 获取 value 对应的展示名
  value = value + '' // 强制转换成字符串，因为 DictData 小类数值，是字符串
  for (const dictData of dictDatas) {
    if (dictData.value === value) {
      return dictData;
    }
  }
  return undefined
}

export function getDictDataLabel(dictType, value) {
  const dict = getDictData(dictType, value);
  return dict ? dict.label : '';
}
